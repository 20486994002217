<script>
import { onMounted, watch, onUnmounted, onBeforeMount, ref, reactive, inject } from 'vue'
import { http } from '@/http'
import { upload } from 'qiniu-js'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { uuid, regFenToYuan, dataURLtoFile } from '@/utils'
import qiniuUpload from '@/utils/qiniu-upload'
import { needLoading } from '@/reactive'
import wx from '@/utils/weixin'
import { activeChannelComponent } from '@/reactive'

export default {
  setup(props, { refs }) {

    let serviceTel = inject('serviceTel')
    // let qrcodeUrl = ref('')
    // let qrcodeRef = ref(null)
    // let qrcode = null
    let rewardMoney = ref(null)
    let invitationRecord = reactive({})
    let inviteBrokerRecord = reactive({})
    let maskLayerVisible = ref(false)
    let maskLayerType = ref('')
    let router = useRouter()
    
    function invitationRecordStats() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.statis')
        .then((res) => {
          if (res.result) {
            for(let item in res.result) {
              invitationRecord[item] = res.result[item]
            }
          }
        })
    }

    function statisInviteBroker() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.statisInviteBroker')
        .then(res => {
          if(res.result) {
            for(let item in res.result) {
              inviteBrokerRecord[item] = res.result[item]
            }
            
          }
        })
    }

    function getRewardMoney() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.getRewardMoney').then(res => {
        if('result' in res) {
          rewardMoney.value = res.result
        }
      })
    }

    function handleCheckStatus() {
      http.post(
        '/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.check'
      ).then((res) => {
        switch (res.errCode) {
        case 45001:
          router.push('/old-verified')
          break
        case 45003:
          router.push('/bind-bankcard')
          break
        case 45004:
          router.push('/trust/result')
          break
        case 45002:
        case 45005:
          router.push('/trust')
          break
        case 0:
          router.push('/income/draw-input')
          break
        default: 
          break
        }

      })
    }

    // watch(() => qrcodeUrl.value, (a, b) => {
    //   // let { id } = JSON.parse(window.localStorage.getItem('userAuth'))
    //   wx.setup({
    //     imgUrl: window.location.origin + '/icon500.png',
    //     title: '猪耳朵商户注册',
    //     desc: '猪耳朵省时、省力、更省钱，聚合平台领跑者',
    //     link: a
    //   })
    // })
    // onBeforeMount(() => {
    //   let auth = JSON.parse(localStorage.getItem('userAuth'))
    //   if(auth.showStatus === 'REAL_AUTH') {
    //     router.push('/verified')
    //   }
    //   else if(auth.showStatus === 'AGRT_SURE') {
    //     router.push('/trust?type=broker')
    //   }
    //   else if(auth.showStatus === 'SERVICE_OPEN') {
    //     router.push('/open-service')
    //   }
      
    // })
   
    onMounted(() => {
      invitationRecordStats()
      statisInviteBroker()
      getRewardMoney()
    })
    
    return {
      serviceTel,
      rewardMoney,
      invitationRecord,
      inviteBrokerRecord,
      handleCheckStatus,
      regFenToYuan,
      maskLayerVisible,
      maskLayerType
    }
  },
}
</script>
<template>
  <div class="page">
    <div class="top-bg">
      <h3>我的收益</h3>
      <p>
        可支取收益(元)<span class="desc-icon" @click="maskLayerVisible = true; maskLayerType = 'a'">?</span>
        <br />
        <span class="price" v-decimal="{num: invitationRecord.balance}"></span>
        <span class="skip-more" @click="$router.push('/settlement/detail')">收益明细</span>
        <span class="btn" @click="handleCheckStatus">支取</span>
      </p>
    </div>
    <div class="r-box">
      <div class="tbl">
        <p class="cell">
          <span v-decimal="{num: invitationRecord.yesterdayrevenue}" />
          昨日收益(元)
        </p>
        <p class="cell">
          <span v-decimal="{num: invitationRecord.preBalance}" />
          待结算收益(元)
        </p>
        <p class="cell" >
          <span v-decimal="{num: invitationRecord.totalIncome}" />
          累计收益(元)
        </p>
      </div>
    </div>
    <div class="r-box">
      <p class="s-title">
        我的商户
        <span @click="$router.push('/invit-record')" style="float: right"
        >&gt;</span
        >
      </p>
      <div class="tbl">
        <p class="cell cell-s1">
          累计商户（家）
          <span>{{ invitationRecord.sum }}</span>
        </p>
        <p class="cell cell-s1">
          已有收益（家）
          <span>{{ invitationRecord.hasSum }}</span>
        </p>
        <p class="cell cell-s1">
          尚未收益（家）
          <span>{{ invitationRecord.hasnotSum }}</span>
        </p>
      </div>
      <p
        style="
          color: #ec0909;
          font-size: 0.24rem;
          text-align: center;
          margin: 15px 0 10px;
        "
      >
        {{ invitationRecord.inteviewDesc }}
      </p>
    </div>
    <div class="r-box">
      <p class="s-title">
        我邀请的渠道商
        <span @click="$router.push('/broker-record')" style="float: right"
        >&gt;</span
        >
      </p>
      <div class="tbl">
        <p class="cell cell-s1">
          累计邀请（家）
          <span>{{ inviteBrokerRecord.all }}</span>
        </p>
        <p class="cell cell-s1">
          已开通
          <span>{{ inviteBrokerRecord.open }}</span>
        </p>
        <p class="cell cell-s1">
          未开通
          <span>{{ inviteBrokerRecord.notOpen }}</span>
        </p>
      </div>
      <p
        style="
          color: #ec0909;
          font-size: 0.24rem;
          text-align: center;
          margin: 15px 0 10px;
        ">
        {{rewardMoney}}
      </p>
    </div>
    <div class="r-box">
      <p class="s-title">
        推广工具
      </p>
      <div class="tbl">
        <div class="cell">
          <p class="blk" @click="$router.push('/channel-promote')">
            <img class="promote" src="@/assets/images/icon-channel-promote.png" />
            渠道推广工具
          </p>
        </div>
        <div class="cell">
          <p class="blk" @click="$router.push('/merchant-promote')">
            <img class="promote" src="@/assets/images/icon-merchant-promote.png" />
            商户推广工具
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="r-box">
      <p class="s-title">
        推广二维码
        <span @click="maskLayerVisible = true; maskLayerType = 'b'" style="float: right; font-size: 0.24rem">分享</span>
      </p>
      <div class="qrcode" id="qrcode" style="visibility: hidden">
      </div>

      <p style="font-size: 0.24rem; text-align: center">
        商户使用微信、支付宝、浏览器扫描二维码注册即可
      </p>
      <p style="font-size: 0.26rem; margin: .4rem 0; text-align: center">长按上方二维码保存</p>

      <p style="font-size: 0.24rem; margin-bottom: 12px">
        客户热线：<a :href="`tel:${serviceTel}`">{{ serviceTel }}</a>
      </p>
    </div> -->
    <div id="desc-overlay" v-show="maskLayerVisible" @click="maskLayerVisible = false">
      <span class="close" @click="maskLayerVisible = false">
        <img src="@/assets/images/icon-close.png" />
      </span>
      <div v-if="maskLayerType === 'a'">
        <p>{{invitationRecord.brokerIncomeDesc}}</p>
        <p>{{invitationRecord.brokerPreIncomeDesc}}</p>
      </div>
      <p v-if="maskLayerType === 'b'" class="share-arrow"><img src="@/assets/images/share-arrow.png" /></p>
    </div> 
  </div>
</template>
<style scoped>

.top-bg {
  overflow: hidden;
  padding: 0 14px;
  margin-bottom: -1.3rem;
  color: #fff;
  height: 4.38rem;
  background-size: 100% 100%;
  background-image: url("~@/assets/images/bg-rd-top.png");
}
.top-bg h3 {
  text-align: center;
  margin: 15px 0 35px;
  font-size: 0.32rem;
}
.top-bg p {
  font-size: 0.24rem;
}
.price {
  margin-top: 0.49rem;
  display: inline-block;
  font-weight: 700;
  font-size: 0.48rem;
}
.skip-more {
  font-size: 0.24rem;
  display: block;
  margin-top: -1.2rem;
  margin-bottom: 0.5rem;
  text-align: right;
}
.skip-more::after {
  content: ">";
  margin-left: 6px;
}
.tbl {
  table-layout: fixed;
  display: table;
  width: 100%;
}
.tbl .cell {
  vertical-align: middle;
  font-size: 0.24rem;
  width: 50%;
  text-align: center;
  display: table-cell;
}
.tbl .cell .blk {
  display: inline-block;
  padding-bottom: 10px;
}
.tbl .cell .promote {
  display: block;
  margin: 0 auto .29rem;
  width: .98rem;
  height: .98rem;
}
.tbl .cell-s1 {
  padding: 10px 0px 0 12px;
  text-align: left;
  font-size: 0.24rem;
  width: auto;
}
.tbl .cell span {
  margin-bottom: .35rem;
  display: block;
  font-size: 0.36rem;
  font-weight: 700;
}
.tbl .cell-s1 span {
  padding-left: 10px;
  margin-top: 18px;
  font-size: 0.28rem;
}
.s-title {
  font-size: 0.3rem;
  margin-bottom: 20px;
}


.btn {
  float: right;
  border-radius: 5px;
  font-size: 0.24rem;
  padding: 0.13rem 0.46rem;
  color: #00abeb;
  background: #fff;
}
.desc-icon {
    border-radius: 100%;
    height: 0.27rem;
    width: 0.27rem;
    line-height:1.1;
    display: inline-block;
    background: #fff;
    color: #00abeb;
    text-align: center;
    margin-left: 0.2rem;
}
#desc-overlay { 
  text-align: center;
  position:fixed;
  top:0; 
  left:0; 
  right:0;
  bottom:0;
  z-index: 1001;
  background:rgba(0, 0, 0, .8);
  display:table; 
  width:100%; 
  height:100%;
  color: #fff;
}
#desc-overlay .close { width: .5rem; height: .5rem; z-index: 1002; position:fixed; right: .3rem; top:.3rem }
#desc-overlay div { padding: 0 .4rem; text-align: justify; display:table-cell; vertical-align:middle }
#desc-overlay h5 { margin-bottom: .3rem; font-size:.37rem }
#desc-overlay p { margin-top: .2rem;  }
.share-arrow { position:absolute; right:0; top:0 }
/deep/ .share-arrow img { transform:rotate(0deg); width: 80%; height: 80%; }
</style>